import { ReactElement } from 'react';
import useSWR from 'swr';

import { SkipTo } from '@components/SkipTo/SkipTo';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { PopularSearch } from '@interfaces/IPopularSearch';
import { cdnClient as client } from '@lib/sanityClient';

function PopularSearchLink({ linkEl }: { linkEl: PopularSearch }) {
  const url = `${
    linkEl.internalLink ? linkEl.internalLink?.slug.current : linkEl.link
  }`;
  return (
    <>
      <li style={{ scrollSnapAlign: 'start' }}>
        <TextLink href={url} rel={linkEl.noFollow ? 'nofollow' : ''}>
          {linkEl.label}
        </TextLink>
      </li>
      <SkipTo pathToBeAccessed={url} />
    </>
  );
}

const fetcher = async (url) => {
  const query = `//groq
    *[_type == "popularSearches" && !(_id in path("drafts.**"))]
    {
      links[]{
        ...,
        "noFollow": coalesce(noFollow, false),
        internalLink->{slug, title}
      }
    }
  `;

  const result = await client.fetch(query);
  if (result && result.length > 0) {
    // array always have a single item because links is the only child of popularSearches in Sanity
    return result[0].links;
  } else {
    const error = new Error('Unable to fetch popular searches');
    throw error;
  }
};

export function PopularSearches(): ReactElement {
  const { data: links } = useSWR<PopularSearch[] | null>(
    '/api/popularSearches',
    fetcher,
    {
      refreshInterval: 3600000, // 60 minutes
      revalidateOnFocus: false,
    }
  );

  if (!links) {
    return <></>;
  }

  return (
    <div
      id="popular-searches"
      className="m-auto flex w-fit max-w-[700px] flex-wrap items-center gap-2.5 px-0 py-1 pb-2.5 
      text-center text-sm sm:pb-1 sm:text-left"
    >
      <span className="mr-auto whitespace-nowrap dark:text-white">
        Popular searches:
      </span>
      <ul className="m-0 flex list-none gap-4 overflow-x-hidden whitespace-nowrap pl-0 sm:overflow-x-visible">
        {links.map((linkEl, linkIndex) => (
          <PopularSearchLink
            key={`${linkEl.label}-${linkIndex}`}
            linkEl={linkEl}
          />
        ))}
      </ul>
    </div>
  );
}

export function PopularSearchesMobile(): ReactElement {
  const { data: links } = useSWR<PopularSearch[] | null>(
    '/api/popularSearches',
    fetcher,
    {
      refreshInterval: 3600000, // 60 minutes
      revalidateOnFocus: false,
    }
  );

  if (!links) {
    return <></>;
  }

  return (
    <div
      id="popular-searches"
      className="m-auto flex flex-wrap items-center gap-2.5 px-0 py-1 pb-2.5 
      text-center text-sm sm:pb-1 sm:text-left"
    >
      <span className="mr-auto whitespace-nowrap dark:text-white">
        Popular searches:
      </span>
      <ul className="m-0 flex list-none flex-wrap gap-4 whitespace-nowrap pl-0">
        {links.map((linkEl, linkIndex) => (
          <PopularSearchLink
            key={`${linkEl.label}-${linkIndex}`}
            linkEl={linkEl}
          />
        ))}
      </ul>
    </div>
  );
}
